'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import type { z } from 'zod';
import { toast } from 'sonner';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@noirproperties/ui/components/ui/form';
import { Button } from '@noirproperties/ui/components/ui/button';
import { Input } from '@noirproperties/ui/components/ui/input';
import { Checkbox } from '@noirproperties/ui/components/ui/checkbox';
import { PhoneInput } from '@noirproperties/ui/components/ui/phone-input';
import { cn } from '@noirproperties/ui/lib/utils';
import { usePathname } from 'next/navigation';
import { trpc } from '@/lib/trpc';
import { useDialog } from '@/contexts/dialog-context';
import { formSchema } from './schema';

type LeadInquiryFormProps = {
  siteUrl: string;
  pageName?: string;
};

export function LeadInquiryForm({
  siteUrl,
  className,
  pageName,
}: LeadInquiryFormProps & React.ComponentProps<'form'>) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fields: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
      consent: {
        gdpr: false,
        marketing: false,
      },
    },
  });

  const path = usePathname();
  const pageUri = new URL(path, siteUrl).toString();

  const { handleOpenChange } = useDialog();
  const mutation = trpc.lead.create.useMutation();

  function onSubmit(values: z.infer<typeof formSchema>) {
    const extraParams = {
      pageUri,
      pageName,
    };

    const mutationData = {
      ...values,
      ...extraParams,
    };

    mutation.mutate(mutationData, {
      onSuccess: () => {
        handleOpenChange(false);
        toast.success('Thank you for your request!', {
          description: 'We will be in touch with you soon.',
        });
      },
      onError: () => {
        toast.error('An error occurred', {
          description: 'Please try again later.',
        });
      },
    });
  }

  const onInvalid = () => {
    toast.error('Invalid form submission', {
      description: 'Please check the form and try again.',
    });
  };

  return (
    <Form {...form}>
      <form
        className={cn('grid items-start gap-1.5', className)}
        onSubmit={(event) => {
          event.preventDefault();
          void form.handleSubmit(onSubmit, onInvalid)(event);
        }}
      >
        <div className="grid gap-2">
          <FormField
            control={form.control}
            name="fields.email"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">E-post</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Your email"
                    {...field}
                    autoComplete="email"
                    className="text-base"
                    type="email"
                  />
                </FormControl>
                <FormDescription className="text-xs">
                  We will send you a confirmation email.
                </FormDescription>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-2">
          <FormField
            control={form.control}
            name="fields.firstName"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">First name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="First name"
                    {...field}
                    autoComplete="given-name"
                    className="text-base"
                  />
                </FormControl>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="fields.lastName"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">Last name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Last name"
                    {...field}
                    autoComplete="family-name"
                    className="text-base"
                  />
                </FormControl>
                {/* <FormMessage className="text-xs italic" /> */}
              </FormItem>
            )}
          />
        </div>

        <div className="grid gap-2">
          <FormField
            control={form.control}
            name="fields.phoneNumber"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel className="text-xs">Phone Number</FormLabel>
                <FormControl className="w-full">
                  <PhoneInput placeholder="Enter a phone number" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>

        <div className="grid gap-2 py-2">
          <FormField
            control={form.control}
            name="consent.gdpr"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel className="text-xs">
                    I consent to Noir Properties storing and processing my
                    personal information.
                  </FormLabel>
                  {/* TODO: Once we have a page with the GDPR policy, we should link to that instead */}
                  {/* <FormDescription className="text-xs">
                    Vi behöver ditt samtycke för att kunna skicka ut tidningen.
                    Du kan läsa mer om hur vi hanterar dina personuppgifter i
                    vår{' '}
                    <Link
                      href="/integritetspolicy"
                      className="font-medium text-sapling-green hover:underline"
                    >
                      integritetspolicy
                    </Link>
                    .
                  </FormDescription> */}
                  {/* <FormDescription className="text-xs">
                    Vi behöver ditt samtycke för att kunna skicka ut tidningen.
                  </FormDescription> */}
                  {/* <FormMessage className="text-xs italic" /> */}
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="consent.marketing"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel className="text-xs">
                    I consent to receive marketing communications from Noir
                    Properties.
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </div>

        <Button className="mt-2 text-sm" type="submit">
          Submit
        </Button>
      </form>
    </Form>
  );
}
