'use client';

import * as React from 'react';
import {
  Button,
  type ButtonProps,
} from '@noirproperties/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader as ShadcnDialogHeader,
  DialogTrigger,
} from '@noirproperties/ui/components/ui/dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader as ShadcnDrawerHeader,
  DrawerTrigger,
} from '@noirproperties/ui/components/ui/drawer';
import { useMediaQuery } from '@/lib/mediaquery/use-media-query';
import { cn } from '@/lib/utils';
import { useDialog } from '@/contexts/dialog-context';
import { DialogHeader } from '@/components/dialog-header';

export type DrawerDialogProps = {
  children: React.ReactNode;
  title: string;
  description: string;
  buttonContent: React.ReactNode;
  cancelButtonText: string;
} & Omit<ButtonProps, 'children'>;

export function DrawerDialog({
  children,
  title,
  description,
  buttonContent,
  cancelButtonText,
  className,
  variant = 'default',
  ...buttonProps
}: DrawerDialogProps) {
  const { isOpen, handleOpenChange } = useDialog();
  const isDesktop = useMediaQuery('(min-width: 768px)');

  if (isDesktop) {
    return (
      <Dialog onOpenChange={handleOpenChange} open={isOpen}>
        <DialogTrigger asChild>
          <Button
            className={cn(
              'font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
              className,
            )}
            variant={variant}
            {...buttonProps}
          >
            {buttonContent}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-2xl">
          <ShadcnDialogHeader className="text-left">
            <DialogHeader description={description} title={title} />
          </ShadcnDialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer
      disablePreventScroll
      onOpenChange={handleOpenChange}
      open={isOpen}
      preventScrollRestoration
    >
      <DrawerTrigger asChild>
        <Button
          className={cn(
            'font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
            className,
          )}
          variant={variant}
          {...buttonProps}
        >
          {buttonContent}
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <ShadcnDrawerHeader className="text-left">
          <DialogHeader description={description} title={title} />
        </ShadcnDrawerHeader>
        <div className="overflow-y-visible px-4">{children}</div>
        <DrawerFooter className="pt-2">
          <DrawerClose asChild>
            <Button variant="outline">{cancelButtonText}</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
