import React from 'react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

type DialogHeaderProps = {
  title: string;
  description: string;
};

export function DialogHeader({ title, description }: DialogHeaderProps) {
  return (
    <div className="flex items-start space-x-4">
      <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 p-2">
        <ChatBubbleBottomCenterTextIcon
          aria-hidden="true"
          className="h-6 w-6 text-green-600"
        />
      </div>
      <div className="flex-1 min-w-0">
        <h2 className="text-lg font-medium text-gray-900">{title}</h2>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      </div>
    </div>
  );
}
