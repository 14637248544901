import { z } from 'zod';

const formFieldsSchema = z.object({
  email: z.string().email({
    message: 'You must provide a valid email address.',
  }),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phoneNumber: z.string().optional(),
});

const consentSchema = z.object({
  gdpr: z.boolean().refine((val) => val, {
    message: 'We need your consent to proceed with your request.',
  }),
  marketing: z.boolean(),
});

export const formSchema = z.object({
  fields: formFieldsSchema,
  consent: consentSchema,
});
